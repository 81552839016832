body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

  body, #root, html, .MuiContainer-root{
    height: 100%;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInput-underline:after{
  border-bottom: #013b6c !important;
}

.polygon{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blank > div {
  background-color: transparent !important;
}
.main{
  overflow-y: auto;
}